









import Vue from 'vue';
import CustomerProfile from '@/components/profile/CustomerProfile.vue';
import ChangePassword from '@/components/profile/ChangePassword.vue';
import ChangeEmail from '@/components/profile/ChangeEmail.vue';
import ChangeEmailSettings from '@/components/profile/ChangeEmailSettings.vue';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  components: { CustomerProfile, ChangePassword, ChangeEmail, ChangeEmailSettings },
  mounted() {
    this.scrollToSection();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.guest.GuestAccountEdit.meta.title').toString(),
    };
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.guest.GuestAccountEdit.breadcrumb.last' },
      ]);
    },

    scrollToSection() {
      var section = this.$router.currentRoute.hash.replace('#', '');
      if (section) {
        this.$nextTick(() => {
          const element = document.getElementById(section);
          if (element) {
            const yOffset = document.getElementsByTagName('header')[0].offsetHeight;
            const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset - 15;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        });
      }
    },
  },
});
