
















































import Vue, { VueConstructor } from 'vue';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import AuthService from '@/services/AuthService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import Notification from '@/components/shared/Notification.vue';

const validations = {
  user: {
    password: {
      required,
    },
    newEmail: {
      required,
      email,
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  mixins: [validationMixin, HelpCardMixin],
  validations,
  components: { ValidatedInput, ValidatedFormCard, Notification },
  data() {
    return {
      user: {
        password: '',
        newEmail: '',
      },
      focusFor: '',
      showSuccessNotification: false,
    };
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.user.newEmail = this.user.newEmail.trim();
      },
    },
  },
  methods: {
    async onSave() {
      const response = await AuthService.changeEmail(this.user);
      if (response.success) {
        this.showSuccessNotification = true;
        this.resetForm();
      }
      return response;
    },

    resetForm() {
      this.user.password = '';
      this.user.newEmail = '';
      this.$v.$reset();
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.profile.ChangeEmail.info.box1';
      }
    },
  },
});
