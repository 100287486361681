








































import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import AccountService from '@/services/users/AccountService';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import Notification from '@/components/shared/Notification.vue';

class EmailSettings {
  allowEmailForBookings!: boolean;
  allowEmailForPersonalOffers!: boolean;
  allowEmailForFeedback!: boolean;
}

const validations = {
  emailSettings: {
    allowEmailForBookings: {},
    allowEmailForPersonalOffers: {},
    allowEmailForFeedback: {},
  },
};

export default Vue.extend({
  components: { ValidatedFormCard, ValidatedCheckbox, Notification },
  mixins: [validationMixin],
  validations,
  data() {
    return {
      emailSettings: new EmailSettings(),
      originalEmailSettings: new EmailSettings(),
      ready: false,
      showSuccessNotification: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      AccountService.getEmailSettings().then((res) => {
        this.emailSettings = res.value as EmailSettings;
        this.originalEmailSettings = { ...this.emailSettings };
        this.ready = true;
      });
    },

    resetForm() {
      this.emailSettings = this.originalEmailSettings;
      this.$v.$reset();
    },

    save() {
      this.emailSettings.allowEmailForBookings = true;
      return AccountService.updateEmailSettings({ ...this.emailSettings }).then((value) => {
        this.showSuccessNotification = true;
        this.originalEmailSettings = { ...this.emailSettings };
        return value;
      });
    },
  },
});
