











import Vue, { VueConstructor } from 'vue';
import CustomerProfileForm from '@/components/profile/CustomerProfileForm.vue';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: {
    CustomerProfileForm,
  },
  mixins: [HelpCardMixin],
  data() {
    return {
      focus: '',
    };
  },
  methods: {
    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'pages.customer.customerProfile.CustomerProfileEdit.info.box1';
      }
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [{ text: 'Meine Daten' }]);
    },
  },
  mounted() {
    this.setBreadCrumb();
  },
});
