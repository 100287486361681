




















































import Vue, { VueConstructor } from 'vue';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import PasswordComplexityValidator from '@/services/validators/PasswordComplexityValidator';
import AuthService from '@/services/AuthService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import Notification from '@/components/shared/Notification.vue';

const validations = {
  user: {
    password: {
      required,
    },
    newPassword: {
      minLength: minLength(12),
      required,
      PasswordComplexityValidator,
    },
    newPasswordConfirmation: {
      minLength: minLength(12),
      sameAsPassword: sameAs('newPassword'),
      required,
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  mixins: [validationMixin, HelpCardMixin],
  validations,
  components: { ValidatedInput, ValidatedFormCard, Notification },
  data() {
    return {
      user: {
        password: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
      focusFor: '',
      showSuccessNotification: false,
    };
  },
  methods: {
    async onSave() {
      var response = await AuthService.changePassword(this.user);
      if (response.success) {
        this.showSuccessNotification = true;
        this.resetForm();
      }
      return response;
    },

    resetForm() {
      this.user.password = '';
      this.user.newPassword = '';
      this.user.newPasswordConfirmation = '';
      this.$v.$reset();
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.profile.ChangePassword.info.box1';
      }
    },
  },
});
